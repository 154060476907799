<template>
  <div
    class="user-profile large-page"
    :key="reloadKey"
  >
    <!-- Show a circular loader while data is being loaded -->
    <!-- @TODO - skeleton loaders -->
    <div v-if="isLoading" class="py-8 text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>

    <!-- In case there's some error -->
    <div v-else-if="computedErrorMessage">
      <!-- Show the back button -->
      <div class="mb-3 mb-md-6">
        <v-btn
          text
          color="primary"
          @click="$router.push({ name: 'InfluencerInsight' })"
        >
          <v-icon left>
            arrow_back
          </v-icon>

          Back
        </v-btn>
      </div>

      <div class="text-center font-weight-bold py-8 py-lg-12">
        {{ computedErrorMessage }}
      </div>

      <div style="max-width: 360px" class="mx-auto">
        <lottie-animation
          loop
          height="200"
          :speed="0.8"
          file="document-not-found.json"
        />
      </div>
    </div>

    <!-- If there's a network response -->
    <template v-else-if="userData">
      <app-view
        :username="username"
        :platform="platform"
        :accountId="computedAccountId"
        :report-user-id="reportUserId"
        :reportId="reportId"
        :user-data="userData"
        :created-at="createdAt"
        :generated-at="generatedAt"
        :overview-data="overviewData"
        :engagement-data="engagementData"
        :audience-data="audienceData"
        :total-values="totalValues"
        :isViewingOldReport="isViewingOldReport"
      />
    </template>
  </div>
</template>

<script>
// Use a helper mixin to compute data
import loadInfluencerInsightData from "@/mixins/loadInfluencerInsightData"

// Import child component
const AppView = () => import(/* webpackChunkName: "user-profile-app-view" */ "@/blocks/user-profile/views/AppView.vue")
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "UserProfile",

  // Register the mixin
  mixins: [
    loadInfluencerInsightData
  ],

  // Register the component
  components: {
    AppView,
    LottieAnimation
  },

  // Accept incoming data from parent
  props: {
    // Since this component is used in several places, we'd be using the attributes to find the user data
    username: {
      type: String,
      required: true,
    },

    platform: {
      type: String,
      required: true
    },

    accountId: {
      type: [String, Number],
      required: false,
      default: null
    },

    reportId: {
      type: [String, Number],
      required: false,
      default: null
    },

    reportUserId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },

  // Local data variables
  data: () => ({
    reloadKey: Symbol(),
    isLoading: true,

    computedAccountId: null,

    userData: null,
    totalValues: null,
    overviewData: null,
    audienceData: null,
    engagementData: null,
    computedErrorMessage: null,

    createdAt: null,
    generatedAt: null,
    isViewingOldReport: false
  }),

  // Register method functions for this component
  methods: {
    /**
     * Listen to when the download loader should be shown
     *
     * @param {Event} e
     * @returns {void}
     */
    handleIsLoading(e) {
      if (
          e.detail.module === "downloadInfluencerInsight" &&
          e.detail.username === this.username &&
          e.detail.platform === this.platform
        ) {
        this.isLoading = e.detail.isLoading
      }
    },

    /**
     * Get the report data from API and then render it in the view
     *
     * @param {Boolean} getRecentReport
     */
    async loadInsights(getRecentReport = false) {
      // When ready, fetch the user data
      const data = await this.loadProfileData({
        username: this.username,
        platform: this.platform,
        accountId: this.computedAccountId || this.accountId,
        reportUserId: this.reportUserId,
        reportId: this.reportId,
        getRecentReport
    })

      // If some data is present
      if (data !== null) {
        // Map them to be available for the template
        this.computedAccountId = data.userData?.data?.user_profile?.user_id || this.accountId

        this.userData = data.userData
        this.createdAt = data.createdAt
        this.generatedAt = data.generatedAt
        this.totalValues = data.totalValues
        this.overviewData = data.overviewData
        this.audienceData = data.audienceData
        this.engagementData = data.engagementData
        this.isViewingOldReport = data.isViewingOldReport
        this.computedErrorMessage = data.computedErrorMessage
      }

      // Finally, re-render the view
      this.reloadKey = Symbol()
    },

    /**
     * Handle the request to update the report
     *
     * @param {Event} e
     * @returns {void}
     */
    handleReportUpdateRequest(e) {
      // Make sure to confirm that we're trying to update this insight
      if (
          e.detail.accountId === this.computedAccountId &&
          e.detail.platform === this.platform
        ) {
        // Also, call to load the data from API
        this.loadInsights(e.detail.getRecentReport || false)
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Listen for isLoading event listeners
    window.addEventListener("isLoading", this.handleIsLoading)

    // Start listening to report update events
    window.addEventListener("InsightReportUpdate", this.handleReportUpdateRequest)

    // Also, call to load the data from API
    this.loadInsights()
  },

  /**
   * Stop listening for event listeners
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Stop listening to loaders
    window.removeEventListener("isLoading", this.handleIsLoading)

    // Stop listening to update events
    window.removeEventListener("InsightReportUpdate", this.handleReportUpdateRequest)
  }
}
</script>

<style lang="stylus">
// For all those cards in overview and other tabs
.user-profile
  .contain-height
    .v-card
      height 315px

  .download-loader
    position fixed
    z-index 101
    left 0
    top 0
    width 100%
    height 100%

    .contain-width
      width 100%
      max-width 20em
</style>
